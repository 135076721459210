import React, { Suspense, useState } from 'react';
import { observer } from 'mobx-react';
import { CamperSearchParams, Overlay, useAppService } from '@ibe/components';
import { ConfigModel } from '@ibe/services';
import initI18next from '../Translations/setup';
import { useMount } from 'react-use';
import { boot, extendedConfigContext } from '../Config/config';
import SearchWidgetContent from '../Components/SearchWidgetContent';
import '../Theme/Styles/DER/dto/Fonts.scss';
import '../Theme/Styles/DER/dto/widgets/searchWidgetExt.scss';
import theme from '../Theme/Styles/DER/dto/theme';
import { ThemeProvider } from '@emotion/react';
import CamperLoading from '../Components/CamperLoading';
import { CmbConfigModel } from '../Config/CmbConfigModel';
import { ApiAffiliateRequestFromJSON } from '@ibe/api';
import ApplyBranding from '../utils/ApplyBranding';
import loadExternalWidgetConfig from '../utils/loadExternalWidgetConfig';

const SearchWidget = observer(function SearchWidget({
  rerouteUrl,
  searchParams,
  cmbConfig,
  affiliateNumber,
  apiKey,
  configOverrides = {}
}: {
  cmbConfig: CmbConfigModel;
  rerouteUrl?: string;
  configOverrides?: Partial<ConfigModel>;
  searchParams?: Partial<CamperSearchParams>;
  affiliateNumber?: string;
  apiKey?: string;
}) {
  const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);
  const [cssVars, setCssVars] = useState<{ [key: string]: string } | undefined>(undefined);
  const [isAffiliateInvalid, setIsAffiliateInvalid] = useState<boolean>(true);
  const appService = useAppService();

  useMount(async () => {
    let baseUrlWithContext: string | undefined;
    const widgetConfig = await loadExternalWidgetConfig();
    if (widgetConfig) {
      baseUrlWithContext = widgetConfig.baseUrl;
      cmbConfig.apiUrl = widgetConfig.apiUrl;
      if (widgetConfig.payoneUrl) {
        cmbConfig.payoneUrl = widgetConfig.payoneUrl;
      }
      cmbConfig.version = widgetConfig.version;
      configOverrides.configUrl = widgetConfig.configUrl;
    }
    await initI18next(baseUrlWithContext, cmbConfig.version);
    await boot(configOverrides, false, cmbConfig, true);
    setBootstrappingFinished(true);
    if (affiliateNumber && apiKey) {
      try {
        const res = await appService.api.getAffiliateData(
          ApiAffiliateRequestFromJSON({
            number: affiliateNumber,
            apiKey: apiKey
          })
        );
        setCssVars(res.cssVars);
        setIsAffiliateInvalid(false);
      } catch (_) {
        setIsAffiliateInvalid(true);
      }
    }
  });

  return bootstrappingFinished ? (
    <>
      {isAffiliateInvalid && <div>Wrong widget configuration, please check.</div>}
      {!isAffiliateInvalid && cssVars && (
        <>
          <ApplyBranding colors={cssVars} />
          <ThemeProvider theme={theme}>
            <Suspense
              fallback={
                <div
                  className="transparent-overlay"
                  style={{ height: '95px', position: 'relative' }}
                >
                  <Overlay fullOpacity customSpinner={<CamperLoading />} />
                </div>
              }
            >
              <extendedConfigContext.Provider value={cmbConfig}>
                <SearchWidgetContent rerouteUrl={rerouteUrl} searchParams={searchParams} />
              </extendedConfigContext.Provider>
            </Suspense>
          </ThemeProvider>
        </>
      )}
    </>
  ) : (
    <div className="transparent-overlay" style={{ height: '95px', position: 'relative' }}>
      <Overlay fullOpacity customSpinner={<CamperLoading />} />
    </div>
  );
});

export default SearchWidget;
