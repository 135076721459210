import * as ReactHabitat from 'react-habitat';
import SearchWidgetExt from '../widgets/SearchWidgetExt';

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const builder = new ReactHabitat.ContainerBuilder();
    builder.register(SearchWidgetExt).as('CmbSearch');
    this.setContainer(builder.build());
  }
}

export default new Habitat();
